<template>
  <div>
    <CSpinner v-if="loading" size="sm" color="primary" />

    <ul class="list-group" v-for="p in $globalVar.courierPermissions" :key="p.id">
      <li class="list-group-item rounded-0">
        <div class="custom-control custom-checkbox">
          <input class="custom-control-input" :id="`permission_${p.id}`" type="checkbox" :value="p.name"
            v-model="courierPermissions">
          <label class="cursor-pointer d-block custom-control-label" :for="`permission_${p.id}`">
            <p class="mb-0">{{ p.name | normalizeSentence }}</p>
            <span class="small font-italic text-muted">{{ p.description }}</span>
          </label>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: "RestaurantPermissions",
  components: {
  },
  props: {
    permissions: { default: [], type: Array },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    courierPermissions: {
      get() {
        return this.permissions;
      },
      set(newValue) {
        this.$emit("update:permissions", newValue);
      },
    }
  },
};
</script>
