<template>
<div>
  <div class="row mb-2">
    <div class="col-sm">
      <CButton
        class="px-0"
        color="link"
        @click="copyMonday"
      >
        <span v-if="isCopied"><CIcon name="cil-check-alt" class="mr-1" />Applied!</span>
        <span v-else><CIcon name="cil-copy" class="mr-1" />Apply Monday to all days</span>
      </CButton>
    </div>
    <div class="col-sm col-6 px-sm-0"><span class="label font-weight-bold">Start Time</span></div>
    <div class="col-sm col-6"><span class="label font-weight-bold">End Time</span></div>
  </div>

  <div v-for="dayObject in days" :key="dayObject.day" class="row my-2 py-1 wh-row" :class="{'table-danger rounded': form[dayObject.day].invalid}">
    <div class="col-sm">
      <div class="d-flex align-items-center flex-gap-2 h-100">
        <CSwitch
          class="mt-0"
          labelOn="YES"
          labelOff="NO"
          color="success"
          size="lg"
          variant="opposite"
          :checked.sync="form[dayObject.day].isSelected"
        />
        <span class="label">{{ dayObject.name }}</span>
      </div>
    </div>

    <div class="col-sm col-6 px-sm-0">
      <CSelectHour :value.sync="form[dayObject.day].start_time" v-if="form[dayObject.day].isSelected" />
      <div v-else class="form-group disable-item">
        <span class="label form-control d-flex align-items-center flex-gap-1"><span class="dot bg-danger"></span> Not working</span>
      </div>
    </div>
    <div class="col-sm col-6">
      <CSelectHour :value.sync="form[dayObject.day].end_time" v-if="form[dayObject.day].isSelected" />
      <div v-else class="form-group disable-item">
        <span class="label form-control d-flex align-items-center flex-gap-1"><span class="dot bg-danger"></span> Not working</span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import CSelectHour from "@/components/CSelectHour";

export default {
  name: "WorkingHours",
  components: {
    CSelectHour
  },
  props: {
    workingHours: { default: () => [], type: Array },
  },
  data() {
    return {
      form: {},
      days: [
        { day: 1, name: 'Monday' },
        { day: 2, name: 'Tuesday' },
        { day: 3, name: 'Wednesday' },
        { day: 4, name: 'Thursday' },
        { day: 5, name: 'Friday' },
        { day: 6, name: 'Saturday' },
        { day: 0, name: 'Sunday' }
      ],
      isCopied: false,
      defaultStartTime: '08:00',
      defaultEndTime: '17:00',
    };
  },

  watch: {
    form: { handler: 'updateWorkingHours', deep: true },
  },

  created() {
    this.days.forEach(dayObject => {
      const existingData = this.workingHours.find(item => item.day_of_week === dayObject.day);

      this.$set(this.form, dayObject.day, {
        "id": existingData?.id ?? null,
        "day_of_week": existingData?.day_of_week ?? dayObject.day,
        "isSelected": !!existingData,
        "start_time": existingData?.start_time ?? this.defaultStartTime,
        "end_time": existingData?.end_time ?? this.defaultEndTime
      });
    });
  },

  methods: {
    updateWorkingHours() {
      this.validate();
      this.$emit("update:workingHours", Object.values(this.form));
    },
    copyMonday() {
      var monday = this.form[1];

      for (var key in this.form) {
        if (key !== "1") {
          this.form[key].isSelected = monday.isSelected;
          this.form[key].start_time = monday.start_time;
          this.form[key].end_time = monday.end_time;
        }
      }

      this.isCopied = true;
      setTimeout(() => this.isCopied = false, 5000);
    },
    validate() {
      var invalid = false;

      // Validate regular working hours.
      for (var key in this.form) {
        const startTime = this.moment(this.form[key].start_time, "HH:mm");
        const endTime = this.moment(this.form[key].end_time, "HH:mm");

        if (this.form[key].isSelected && startTime >= endTime) {
          this.form[key].invalid = true;
          invalid = true;
        } else {
          this.form[key].invalid = false;
        }
      }

      this.$emit('validate', invalid);
    }
  },
};
</script>

<style scoped>
span.label {
  font-size: 1.125rem;
  font-weight: 500;
}
.wh-row .form-group {
  margin-bottom: 0rem;
}
</style>
