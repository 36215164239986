<template>
  <div>
    <CButton @click="getContracts" color="info" variant="ghost" shape="pill" size="sm" :disabled="loading">
      <div class="vertical-center flex-gap-2">
        <CSpinner v-if="loading" size="sm" />
        <CIcon v-else name="cil-history" /> Get Contracts
      </div>
    </CButton>

    <CDataTable
      v-if="contracts.length"
      class="table-couriers"
      striped
      hover
      :items="contracts"
      :fields="fields"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No contracts found!',
      }"
    >
      <!-- Contract model -->
      <template #contract_model="{item}">
        <td>
          {{ item.contract_model | normalizeSentence | uppercase }}
        </td>
      </template>

      <!-- Employment type -->
      <template #employment_type="{item}">
        <td>
          {{ item.employment_type | normalizeSentence | uppercase }}
        </td>
      </template>

      <template #rate="{item}">
        <td>
          {{ item.rate | toCurrency }}
        </td>
      </template>

      <!-- Start date -->
      <template #start_date="{item}">
        <td>{{ moment(item.start_date).format('LL') }}</td>
      </template>

      <!-- End date -->
      <template #end_date="{item}">
        <td>{{ item.end_date ? moment(item.end_date).format('LL') : '&hellip;' }}</td>
      </template>
    </CDataTable>
  </div>
</template>

<script>
export default {
  name: "ContractHistory",
  props: {
    courierId: { type: String, default: null },
  },
  data() {
    return {
      contracts: [],
      fields: [
        { key: "contract_model", sorter: false, _style: "min-width: 140px;" },
        { key: "employment_type", _style: "min-width: 140px;" },
        { key: "rate", _style : "min-width: 120px;width: 120px;" },
        // { key: "vat_number", _style : "min-width: 200px;" },
        // { key: "company_name", filter: false, _style: "min-width: 140px; width: 140px;" },
        { key: "start_date", filter: false, _style: "min-width: 140px; width: 140px;" },
        { key: "end_date", filter: false, _style: "min-width: 140px; width: 140px;" },
      ],
      loading: false,
    };
  },
  methods: {
    async getContracts() {
      try {
        this.loading = true;
        const { data } = await this.$axios.get(this.$backend.COURIER.CONTRACTS.GET_ALL.replace("{id}", this.courierId), {
          params: { },
        });

        this.contracts = data.data.contracts;
      } catch (error) {
        this.contracts = [];
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>
