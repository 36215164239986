<template>
<div class="custom-wh">
  <cite>Custom working hours will override regular working hours.</cite>

  <div class="row mt-4 mb-2">
    <div class="col-sm-6 text-center">
      <CButton
        color="success"
        size="sm"
        variant="outline"
        shape="pill"
        @click="pushCwh"
      >
        <CIcon name="cil-plus" />
      </CButton>
    </div>
    <div class="col-sm-3 col-6 px-sm-0"><span class="label font-weight-bold">Start Time</span></div>
    <div class="col-sm-3 col-6"><span class="label font-weight-bold">End Time</span></div>
  </div>

  <div v-for="(cwh, index) in formCwh"
    class="row my-2 py-1 wh-row"
    :class="{ 'table-danger rounded': cwh.invalid, 'disable-item strikeout': cwh.deleted }"
  >
    <div class="col-sm-6">
      <div class="d-flex align-items-center flex-gap-2">
        <CButton
          class="ml-1"
          size="sm"
          color="danger"
          variant="ghost"
          shape="pill"
          @click="deleteItem(cwh, index)"
        >
          <CIcon name="cil-trash" />
        </CButton>

        <CInputCheckbox
          style="min-width: fit-content;"
          label="Off Day"
          custom
          :checked.sync="cwh.is_off"
        />

        <div class="form-group flex-fill">
          <input
            type="date"
            v-model="cwh.custom_date"
            class="form-control"
            :min="today"
          />
        </div>
      </div>
    </div>

    <div class="col-sm-3 col-6 px-sm-0">
      <CSelectHour v-if="!cwh.is_off" :value.sync="cwh.start_time" />
    </div>
    <div class="col-sm-3 col-6">
      <CSelectHour v-if="!cwh.is_off" :value.sync="cwh.end_time" />
    </div>
  </div>
</div>
</template>

<script>
import CSelectHour from "@/components/CSelectHour";

export default {
  name: "CustomWorkingHours",
  components: {
    CSelectHour
  },
  props: {
    customWorkingHours: { default: () => [], type: Array },
  },
  data() {
    return {
      // formCwh: null,
      defaultStartTime: '08:00',
      defaultEndTime: '17:00',
    };
  },

  computed: {
    today() {
      return new Date().toISOString().slice(0,10);
    },
    tomorrow() {
      const today = new Date() // get today's date
      const tomorrow = new Date(today)
      tomorrow.setDate(today.getDate() + 1) // Add 1 to today's date and set it to tomorrow
      return tomorrow.toISOString().slice(0,10);
    },
    formCwh: {
      get() {
        return this.customWorkingHours;
      },
      set(newValue) {
        this.$emit("update:customWorkingHours", newValue);
      },
    },
  },

  watch: {
    formCwh: { handler: 'validate', deep: true },
  },

  methods: {
    validate() {
      var invalid = false;

      // Validate custom working hours.
      for (var key in this.formCwh) {
        if (this.formCwh[key].deleted) {
          this.formCwh[key].invalid = false;
          continue;
        }

        const hasMultipleSameCustomDate = this.formCwh
          .filter((wh) => !wh.deleted)
          .hasMultipleRecords('custom_date', this.formCwh[key].custom_date);

        if (this.formCwh[key].is_off) {
          this.formCwh[key].invalid = invalid = hasMultipleSameCustomDate || false;
          continue;
        } else {
          this.formCwh[key].is_off = null;
        }

        const today = (new Date()).setHours(0, 0, 0, 0);
        const customDate = (new Date(this.formCwh[key].custom_date)).setHours(0, 0, 0, 0);
        const startTime = this.moment(this.formCwh[key].start_time, "HH:mm");
        const endTime = this.moment(this.formCwh[key].end_time, "HH:mm");

        if (startTime >= endTime || customDate < today || hasMultipleSameCustomDate) {
          this.formCwh[key].invalid = true;
          invalid = true;
        } else {
          this.formCwh[key].invalid = false;
        }
      }

      this.$emit('validate', invalid);
    },
    pushCwh() {
      this.formCwh.push({
        "custom_date": this.today,
        "start_time": this.defaultStartTime,
        "end_time": this.defaultEndTime,
        is_off: null,
        invalid: false,
      });
    },
    deleteItem(item, index) {
      if (item.id) {
        this.$set(this.formCwh, index, { ...item, deleted: true });
      } else {
        this.formCwh.splice(this.formCwh.indexOf(item), 1);
      }
    }
  },
};
</script>

<style scoped>
span.label {
  font-size: 1.125rem;
  font-weight: 500;
}
.wh-row .form-group {
  margin-bottom: 0rem;
}
</style>
